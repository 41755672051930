import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { BannerVideo } from "../components/banner-video"
import { TiggbeeContactUs } from "../components/tiggbee-contact-us"
import { HomepageBannerContent } from "../components/homepage-banner-content"
import { HomepageContent } from "../components/homepage-content"
// import home_page_banner from '../images/home-page-banner.png';

const IndexPage = () => (
  <Layout>
    <SEO title="Home Page" />
    <BannerVideo
      videoURL="/tiggbee-banner-video-cut-02-low-rez-720p.mp4"
      imgHeight={767}
      content={<HomepageBannerContent />}
    />
    <HomepageContent />
    <TiggbeeContactUs
      titleElement={
        <>
          Our Mission is to empower our students regardless of economic or
          geographic barriers with the hope and knowledge of the opportunities
          to be happy and successful individuals.
        </>
      }
    />
  </Layout>
)

export default IndexPage
